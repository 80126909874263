.Landing {
  width: 100%;
  height: 100vh;

  &__withbackground {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;

    &__body {
      position: absolute;
      transform: translateY(60%);
    }
  }

  &__background-0 {
    background: url("/images/backgrounds/landing-background0.jpg") center center
      fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__background-1 {
    background: url("/images/backgrounds/landing-background1.jpg") center center
      fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__background-mobile-0 {
    background: url("/images/backgrounds/mobile/landing-background0.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
  }

  &__background-mobile-1 {
    background: url("/images/backgrounds/mobile/landing-background1.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
  }

  #landing_step1{
    transition: width 1s, transform 1s;
    float: left;
  }
  #landing_step1.slide{
    width: 0;
  }
}
