@import "../../../common/sass/variables";

.conteneur-reponse {
  .list-group-item {
    border: none;
    border-radius: .5rem !important;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
    -moz-box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
    box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
    .num_reponse {
      padding: .5rem .6rem;
      border-radius: 100%;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
      -moz-box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
      box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
      img {
        width: 1.25rem;
      }
    }
  }
  .list-group-item:hover {
    transform: scale(1.01);
    transition: all .25s;
    background: rgba(123, 131, 235, 0.1);
  }
  .list-group-item.active {
    background: rgba(123, 131, 235, 0.15);
    border: solid 1px $color-primary-light;
    color: initial;
  }
}
.conteneur-reponse-img {
  .conteneur-img {
    .check-img {
      input {
        margin: 0;
      }
    }
  }
  .conteneur-img:hover{
    transform: scale(1.05);
    transition: all .25s;
  }
  .conteneur-img.img-active {
    border: solid 2px $color-primary-light;
  }
}