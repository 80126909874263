@import "../../common/sass/variables";
@import "../../common/sass/common";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

html,
body,
#root {
  height: 100%;
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-black1;
  background-size: 100%;
  background-attachment: fixed;

  .teams_logo {
    max-width: 442px;
  }

  .main-container {
    .main-content {
      min-height: 100%;
      height: fit-content;
    }
    .main-encart {
      @include bg-encart;
      padding: 1.25rem 1.5rem;
      margin-bottom: 2rem;
    }
  }
  .main-container.gradient {
    background: rgb(244, 244, 255);
    background: linear-gradient(180deg, rgba(244, 244, 255, 1) 0%, rgba(218, 216, 240, 1) 100%);
  }
  .main-container.overlay-purple {
    background: url("/images/overlays/overlay-purple.png") no-repeat;
    background-size: cover;
    color: $color-white1;
    .main-encart:not(.nobg-mobile) {
      color: initial;
    }
  }
  .main-container.overlay-mauve {
    background: url("/images/overlays/overlay-mauve.png") no-repeat;
    background-size: cover;
    color: $color-white1;
    .main-encart:not(.nobg-mobile) {
      color: initial;
    }
  }

  /*** modal ***/
  .modal-dialog {
    .modal-content {
      padding: 1rem;
      border-radius: 0.5rem;
      background: #fff url("/images/overlays/overlay_modal.jpg") no-repeat;
      background-size: cover;
      background-position: left top;
      .modal-header {
        border: none;
      }
      .modal-footer {
        border: none;
      }
    }
  }
  .modal-backdrop {
    background: $color-primary-light;
    backdrop-filter: blur(4px);
  }

  @include media-breakpoint-up(md) {
    .modal-backdrop {
      background: none !important;
      backdrop-filter: initial; 
    }
  }
  .modal-mobile {
    margin: 0;

    .modal-content {
      padding: 0;
      padding-top: 3em; //logo padding
      //background: url("/images/overlays/overlay-blue-mobile.png");
      background: url("/images/overlays/overlay-blue.png");
      background-size: cover;
      background-repeat: no-repeat;

      .modal-body {
        background: none;
        padding: 0;
      }
    }
  }

  /*** fin modal ***/

  /*** tooltip ***/
  .tooltip {
    .tooltip-inner {
      background: $color-primary-light;
      padding: 0.75rem;
      text-align: left;
      max-width: 500px;
    }
  }
  .tooltip.show {
    opacity: 1;
  }
  .tooltip.bs-tooltip-top {
    .arrow::before {
      border-top-color: $color-primary-light;
    }
  }
  .tooltip.bs-tooltip-right {
    .arrow::before {
      border-right-color: $color-primary-light;
    }
  }
  .tooltip.bs-tooltip-bottom {
    .arrow::before {
      border-bottom-color: $color-primary-light;
    }
  }
  .tooltip.bs-tooltip-left {
    .arrow::before {
      border-left-color: $color-primary-light;
    }
  }
  /*** fin tooltip ***/
}

@include media-breakpoint-down(md) {
  body {
    background: url("/images/overlays/overlay-purple.png") no-repeat top left;
    background-size: cover;
    .nobg-mobile {
      background: none !important;
      box-shadow: none !important;
    }
  }
}

@include media-breakpoint-up(md) {
  body {
    background: url("/images/backgrounds/fond_principal_decore.jpg") no-repeat top left;
    font-size: 0.85rem !important;
    line-height: 1.35;
    .modal-dialog {
      max-width: 800px;
    }
  }
}
