@import "../../../common/sass/variables";
@import "~video.js/dist/video-js.min.css";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.conteneur-reponse {
  .video-js {
    width: 100%;
    height: 100%;
  }
  .list-group-item {
    border: none;
    border-radius: .5rem !important;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
    -moz-box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
    box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
    .num_reponse {
      padding: .5rem .6rem;
      border-radius: 100%;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
      -moz-box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
      box-shadow: 0px 0px 5px 0px rgba(123, 131, 235, 0.4);
      img {
        width: 1.25rem;
      }
    }
  }
  .list-group-item.active {
    background: rgba(123, 131, 235, 0.15);
    border: solid 1px $color-primary-light;
    color: initial;
  }
}

@include media-breakpoint-down(md) {
  .conteneur-reponse {
    .video-js {
      height: 12rem;
    }
  }
}
